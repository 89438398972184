<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">迴響資料管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <!-- <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button> -->
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入迴響資料" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300" @click="grid.onPrint({ mode: 'curret' })">
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="initData"
          @removeSelectedRows="hideBatchDropDown()"
          @modalResize="onGridModalResize"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-member-id="{ data }">
              <select-box v-bind="memberSelectorOptions" v-model="data.MemberId" :readonly="true" :disabled="true" />
            </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="close"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '迴響資料管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: false,
      canUpdate: false,
      multiselect: false,
      printConfig: { sheetName: '迴響資料管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '迴響資料管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'CreatedTime', title: '建立時間', showOverflow: true, sortable: true, resizable: true, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm') : '' },
        { field: 'Publication.Title', title: '標題', showOverflow: true, sortable: true, resizable: true },
        { field: 'Member.Person.Name', title: '會員名稱', showOverflow: true, sortable: true, resizable: true },
        { field: 'Score', title: '文章評分', showOverflow: true, sortable: true, resizable: true,
          formatter: ({ cellValue }) => {
            const item: any = Scores.find((e: any) => e.value === cellValue);
            return item ? item.label : "";
          }
        },
        //{ field: 'UserIp', title: '使用者IP', showOverflow: true, sortable: true, resizable: true },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('feedback/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('feedback/query') : undefined,
        save: model ? (params) => model.dispatch('feedback/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const Scores = [
      { label: "非常滿意", value: 1 },
      { label: "滿意", value: 2 },
      { label: "普通", value: 3 },
      { label: "尚可", value: 4 },
      { label: "不滿意", value: 5 },
      { label: "非常不滿意", value: 6 },
    ]

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'CreatedTime',
          title: '建立時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd HH:mm', disabled: 'true', placeholder: '請輸入日期', clearable: true } }          
        },
        {
          field: 'Publication.Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true, disabled: true } }          
        },
        {
          field: 'MemberId',
          title: '會員名稱',
          span: 12,
          slots: { default: "column-member-id" }
        },
        {
          field: 'Score',
          title: '文章評分',
          span: 12,
          //itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入評分', disabled: true }, }
          itemRender: { name: '$select', props: { disabled: 'true' }, options: Scores }
        },
        {
          field: 'UserIp',
          title: '使用者IP',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true, disabled: true } }
        },
        { field: 'Comment', title: '觀點分享', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: true } } },
      ],
      rules: {
      }
    }
    
    const initData = (row: any, callback: any) => {
      callback();
    }

    const close = () => {
      grid.value.isModalPopup = false;
    }

    const memberSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      disabled: false,
      columns: [
        {
          field: "Organization.Name",
          title: "公司名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Person.Department",
          title: "部門",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Person.JobTitle",
          title: "職稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Account",
          title: "帳號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("member/find", value), // eslint-disable-line
        query: params => model!.dispatch("member/query", params) // eslint-disable-line
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      initData,
      Scores,
      close,
      memberSelectorOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
