
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '迴響資料管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: false,
      canUpdate: false,
      multiselect: false,
      printConfig: { sheetName: '迴響資料管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '迴響資料管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'CreatedTime', title: '建立時間', showOverflow: true, sortable: true, resizable: true, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm') : '' },
        { field: 'Publication.Title', title: '標題', showOverflow: true, sortable: true, resizable: true },
        { field: 'Member.Person.Name', title: '會員名稱', showOverflow: true, sortable: true, resizable: true },
        { field: 'Score', title: '文章評分', showOverflow: true, sortable: true, resizable: true,
          formatter: ({ cellValue }) => {
            const item: any = Scores.find((e: any) => e.value === cellValue);
            return item ? item.label : "";
          }
        },
        //{ field: 'UserIp', title: '使用者IP', showOverflow: true, sortable: true, resizable: true },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('feedback/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('feedback/query') : undefined,
        save: model ? (params) => model.dispatch('feedback/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const Scores = [
      { label: "非常滿意", value: 1 },
      { label: "滿意", value: 2 },
      { label: "普通", value: 3 },
      { label: "尚可", value: 4 },
      { label: "不滿意", value: 5 },
      { label: "非常不滿意", value: 6 },
    ]

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'CreatedTime',
          title: '建立時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd HH:mm', disabled: 'true', placeholder: '請輸入日期', clearable: true } }          
        },
        {
          field: 'Publication.Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true, disabled: true } }          
        },
        {
          field: 'MemberId',
          title: '會員名稱',
          span: 12,
          slots: { default: "column-member-id" }
        },
        {
          field: 'Score',
          title: '文章評分',
          span: 12,
          //itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入評分', disabled: true }, }
          itemRender: { name: '$select', props: { disabled: 'true' }, options: Scores }
        },
        {
          field: 'UserIp',
          title: '使用者IP',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true, disabled: true } }
        },
        { field: 'Comment', title: '觀點分享', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, readonly: true } } },
      ],
      rules: {
      }
    }
    
    const initData = (row: any, callback: any) => {
      callback();
    }

    const close = () => {
      grid.value.isModalPopup = false;
    }

    const memberSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      disabled: false,
      columns: [
        {
          field: "Organization.Name",
          title: "公司名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Person.Department",
          title: "部門",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Person.JobTitle",
          title: "職稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Account",
          title: "帳號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("member/find", value), // eslint-disable-line
        query: params => model!.dispatch("member/query", params) // eslint-disable-line
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      initData,
      Scores,
      close,
      memberSelectorOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
